/*
General Styling for tags
*/
* {
  font-family: "Libre Franklin";
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  box-sizing: border-box;
  color: rgb(24, 24, 24);
  transition: 0.6s;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
}

header {
  position: relative;
  z-index: 1000;
}

a {
  text-decoration: none;
}

u {
  text-decoration: underline;
  display: inline;
}

b {
  font-weight: 900;
  display: inline;
}

p {
  font-size: 16px;
}

/*
General styling for pages
*/

#content-container {
  height: 100%;
  z-index: -3;
}

#footer-info {
  flex-basis: auto;
  letter-spacing: 2px;
  width: 500px;
  margin: 0 auto;
  font-size: 14px;
  padding-bottom: 12px;
  text-align: center;
}

#copyright {
  letter-spacing: 2px;
  width: 500px;
  margin: 0 auto;
  font-size: 12px;
  padding-bottom: 16px;
  text-align: center;
}