.resume-main-content {
    display: flex;
    height: calc(100vh-176);
    align-items: center;
}

.resume-subtitle {
    margin-left: 5%;
    width: 500px;
    font-size: 18px;
    display: block;
    
}

.resume {
    margin: auto;
    margin-top: 5%;
    height: 800px;
    width: 800px;
}

.resume-download {
    font-size: 15px;
    margin-left: 6px;
    justify-content: left;
}

.resume-download:hover {
    background-color: rgb(123, 149, 194)
}

.social-icons{
    font-size: 30px;
    margin-left: 5%;
}

.social-icons {
    margin-bottom: 2vh;
    font-size: 42px;
}

.social-icons > *  {
    margin-right: 20px;
    cursor: pointer;
    transition: 0.5s;
}

.social-icons > *:hover  {
    opacity: 0.3;
}

.social-icons svg {
    color: black;
}